import Link from 'next/link';
import styles from './includes-relation.module.scss'
import { Box } from '@mui/material';

const Item = ({ product, brands }) => {
    const brandName = brands.at(0)?.label || null

    return (
        <Link href={product.canonical_url} className={styles.listItem}>
            <Box className={styles.image} style={{ backgroundImage: `url(${product.small_image.url})` }} >
                <Box className={styles.brand}>{brandName}</Box>
                <Box className={styles.quantity}>X{product.qtyInKit}</Box>
            </Box>
            <Box className={styles.name + ' fs-12'}>{product.name}</Box>
        </Link>
    );
}

export default Item;