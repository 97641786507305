/* eslint-disable @next/next/no-img-element */
import { useContext, useState } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import WishListSection from '../../product-card/wishListSection';
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import CancelIcon from '@mui/icons-material/Cancel';
import Specs from "../product-details/specs";
import styles from './product-variants.module.scss'
import { useGlobalStore } from "../../../stores/globalStore";
import { Box, Button, Grid } from "@mui/material";
import { MultiWishListContext } from "../../../Providers/MultiWishListContext";
import MyModal from "../../my-modal/myModal";
import cssVars from '../../../styles/vars.module.scss'
import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_CUSTOMIZATION } from "../../../graphql/products/pdpQueries";
import { useAuthStore } from "../../../stores/authStore";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import StockStatus from "../../stock-status/stockStatus";

const VariantModal = ({ varOptions, variant, modalShow, setModalShow, getVariantAttrValue, getVariantAttrHighlighted, addToCart }) => {
    const [showModalWishLists, setShowModalWishLists] = useState(false)

    const { wishLists: data, isFav, addProductToWishlist, removeProductFromWishlist } = useContext(MultiWishListContext)

    const isAriba = useGlobalStore(s => s.isAriba)
    const isAuthenticated = useAuthStore(state => state.token)?.length > 0
    const router = useRouter()
    const { enqueueSnackbar } = useSnackbar()

    const handleFavClick = () => {
        if (isAuthenticated) {
            setShowModalWishLists(prev => (!prev));
        } else {
            router.push(`/customer/login.html?redirect=${router.asPath}`)
        }
    };

    const { data: customerCustomization, loading: customerCustomizationLoading } = useQuery(GET_CUSTOMER_CUSTOMIZATION, { variables: { sku: variant?.sku }, skip: (!variant?.sku || !modalShow), context: { fetchOptions: { method: "GET" } } })
    const attributes = customerCustomization?.products?.items?.at(0)?.customer_customization?.attributes
    console.log(customerCustomization);

    return (
        <MyModal

            open={modalShow}
            setOpen={setModalShow}
            sx={{ maxWidth: "90%", borderColor: cssVars.extraDarkGray, outline: "none !important" }}
        >
            <Grid container columns={12} width="100%" columnSpacing={{ lg: 3 }} display="flex" alignItems="center" mx={0}>
                <Grid item lg={3} position="relative">
                    <img src={variant.small_image.url} alt="variant image" style={{ width: "100%" }} />
                </Grid>
                <Button style={{ position: "absolute", right: "0.8rem", top: "14px", border: "none", background: "none", padding: "0", width: "fit-content", zIndex: "2" }} onClick={() => setModalShow(false)}><CancelIcon htmlColor='#0072BA' fontSize="large" /></Button>
                <Grid item lg={9} >
                    <Box component="p" className={styles.name}>{variant.name}</Box>
                    <Box component="p" className={styles.model_no}>{variant.model_no}</Box>
                    <Grid container columns={12}>
                        <Grid item xs={12} lg={6} display="flex" alignItems="center" flexWrap="wrap">
                            {
                                varOptions.map((option, i) =>
                                    <Box
                                        key={i}
                                        sx={{
                                            fontSize: 12,
                                            px: 1,
                                            fontWeight: 500,
                                            py: .5,
                                            textAlign: 'center',
                                            width: 'fit-content',
                                            borderRadius: 25,
                                            minWidth: 60,
                                            backgroundColor: getVariantAttrHighlighted(option) ? cssVars.blue : 'initial',
                                            color: getVariantAttrHighlighted(option) ? cssVars.white : 'initial',
                                            border: getVariantAttrHighlighted(option) ? `1px solid ${cssVars.blue}` : `1px solid ${cssVars.lightBlack}`,
                                            mr: 1,
                                            mb: 1,
                                        }}
                                    >
                                        {getVariantAttrValue(option)}
                                    </Box>
                                )
                            }
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "end", mt: { xs: 3, lg: 0 }, position: "relative" }}>
                                <Box mt={{ xs: 3, lg: 0 }}><StockStatus product={variant} variant="full" /></Box>
                                <Button
                                    className={`${styles['add-btn']}`}
                                    sx={{
                                        mt: { xs: 3, lg: 0 },
                                        backgroundColor: cssVars.blue,
                                        '&:hover': {
                                            bgcolor: cssVars.darkBlue
                                        },
                                        color: cssVars.white,
                                        bgcolor: cssVars.blue,
                                        width: 36,
                                        height: 36,
                                        minWidth: "unset",
                                    }}
                                    onClick={(e) => { addToCart(e, variant, true) }}
                                    disabled={variant?.availability?.status === "not-available"}
                                >
                                    <ShoppingBasketIcon sx={{ m: "0px !important" }} />
                                </Button>
                                {/* <Button
                                    aria-label="add to wishlist"
                                    className={styles['fav-btn']}
                                    sx={{
                                        mt: { xs: 3, lg: 0 },
                                        visibility: isAriba ? 'hidden' : 'visible',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        color: isFav(variant.sku) ? cssVars.blue : cssVars.darkGray,
                                        border: `1px solid`,
                                        borderColor: isFav(variant.sku) ? cssVars.blue : cssVars.darkGray,
                                        '&:hover': {
                                            color: isFav(variant.sku) ? cssVars.darkBlue : cssVars.extraDarkGray,
                                            borderColor: isFav(variant.sku) ? cssVars.darkBlue : cssVars.darkBlue
                                        },
                                        minWidth: "unset"

                                    }}
                                    onClick={() => handleFavClick()}
                                >
                                    <FavoriteIcon />
                                </Button> */}
                                {/* <WishListSection
                                    sku={variant.sku}
                                    show={showModalWishLists}
                                    setShowWishLists={setShowModalWishLists}
                                    sx={{ top: { xs: 64, lg: 42 }, right: 0 }}
                                /> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box mt={2} >
                <Specs attributes={attributes} customerCustomizationLoading={customerCustomizationLoading} sx={{ maxHeight: "200px", overflowY: "auto" }} />
            </Box>
        </MyModal>
    )
}

export default VariantModal