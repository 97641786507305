import ProductCard from "../../../components/product-card";
import styles from './relation.module.scss'
import Item from "./item";
import Carousel from "../../carousel";
import { Box } from "@mui/material";
import cssVars from "../../../styles/vars.module.scss";

const Relation = ({ products, title, brands }) => {

    const responsive = {
        0: {
            items: 1,
            nav: false,
            dots: true,
            margin: 12
        },
        550: {
            items: 2,
            margin: 17,
            nav: false,
            dots: true
        },
        900: {
            items: 3,
            margin: 35,
            nav: false,
        },

        1200: {
            items: 4,
            margin: 35
        },
    }

    return (
        products?.length > 0
            ?
            <Box component="section" my={10}>
                <Box display={{ xs: "none", lg: "block" }} className={styles['relation']}>
                    <Box component="h2" fontSize={30} mb={1.5} color={cssVars.extraDarkGray}>{title}</Box>
                    <Box className={styles.list}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: { xs: 'space-between', lg: 'flex-start' } }}>
                            {
                                products.map((product, i) =>
                                    <Item key={product.sku} product={product} brands={brands} />
                                )
                            }
                        </Box>
                    </Box>
                </Box>
                <Box position="relative" display={{ xs: "block", lg: "none" }} className={styles['relation']}>
                    <Box component="h2" fontSize={30} mb={1.5} color={cssVars.extraDarkGray}>{title}</Box>
                    <Carousel
                        className="owl-theme products-carousel"
                        dots={true}
                        nav={true}
                        responsive={responsive}
                    >
                        {
                            products.map((product, i) =>
                                <ProductCard key={product.sku} product={product} />
                            )
                        }
                    </Carousel>
                </Box>
            </Box>
            :
            null
    );
}

export default Relation;