import BreadCrumb from "../breadcrumb/breadcrumb";
import ProductDetails from "./product-details";
import Relation from "./relation";
import Variants from "./variants";
import IncludesRelation from "./includes-relation";
import StructuredData from "./structured-data";
import usePDPDetails from "./usePDPdetails";
import { Box } from "@mui/material";
import PDPSelections from "./pdpSelections";
import PDPCampaign from "./pdpCampaign";
import PdpBreadcrumb from "./pdpBreadcrumb";
import useTranslation from "../../hooks/useTranslation";

const PDP = ({ url, initialData }) => {

    const ppData = usePDPDetails({ sku: initialData.sku })
    const {
        product,
        accessories,
        spareParts,
        suitableParts,
        usedWith,
        productVariants,
        variantOptions,
        parentVariantAttributes,
        productKit,
        productParentKit,
        similarProducts,
        upSellProducts,
        productFiles: files,
        attributes,
        breadcrumbs,
        ppqLoading,
        relatedProductsLoading,
        variantsLoading,
        customerCustomizationLoading,
        campaign,
        selections
    } = ppData

    const { t } = useTranslation()

    return (
        <Box className="container product-page">
            <Box px={{ xs: 1, lg: 0 }}>
                <StructuredData initialData={initialData} />

                <PdpBreadcrumb breadcrumbs={breadcrumbs} product={product} ppqLoading={ppqLoading} />

                <ProductDetails product={product} similarProducts={similarProducts}
                    brands={initialData.customer_customization.brands} initialData={initialData}
                    attributes={attributes} customerCustomizationLoading={customerCustomizationLoading} variantsLoading={variantsLoading}
                    url={url} disable={productVariants?.length > 0} hasVariants={productVariants?.length > 0}
                    ppqLoading={ppqLoading}
                    relatedProductsLoading={relatedProductsLoading}
                    files={files}
                />

                <Variants variants={productVariants} variantOptions={variantOptions} parentVariantAttributes={parentVariantAttributes} />

                <Relation title={t('Accessoires')} products={accessories} brands={initialData.customer_customization.brands} />

                <Relation title={t('Spare parts')} products={spareParts} brands={initialData.customer_customization.brands} />

                <Relation title={t('Suitable parts')} products={suitableParts} brands={initialData.customer_customization.brands} />

                <Relation title={t('Used with')} products={usedWith} brands={initialData.customer_customization.brands} />

                <IncludesRelation title={t(`Contenu de l'assortiment`)} products={productKit} brands={initialData.customer_customization.brands} />

                <Relation title={t('Inclus Dans')} products={productParentKit} brands={initialData.customer_customization.brands} />

                <Relation title={t('Other similar products')} products={upSellProducts} brands={initialData.customer_customization.brands} />

                <PDPCampaign campaign={campaign} />

                <PDPSelections selections={selections} />
            </Box>
        </Box>
    );

}

export default PDP;