import { Box, Grid } from "@mui/material";
import SimilarProductCard from "./similarProductCard";
import style from './similarProducts.module.scss'

const SimilarProducts = ({ products }) => {

    const getURL = (product) => {
        if (product.my_url) {
            return "/" + product.my_url
        }
        else {
            return "/" + product.canonical_url
        }
    }


    return (
        <Grid container mt={2.5} columns={12} spacing={2} display="flex" justifyContent="start" className={style['similar-products']} >
            {
                products.sort((a, b) => { return (a.sku > b.sku ? -1 : 1) }).map(product =>
                    <SimilarProductCard key={product.sku} product={product} url={getURL(product)} style={style} />
                )
            }
        </Grid>
    );
}

export default SimilarProducts;