import styles from './product-variants.module.scss'
import InfoIcon from '@mui/icons-material/Info';
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import useTranslation from '../../../hooks/useTranslation'
import { useContext, useState } from 'react';
import FavoriteIcon from "@mui/icons-material/Favorite";
import WishListSection from '../../product-card/wishListSection';
import { WishListContext } from '../../../Providers/WishListContext';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import BlockIcon from '@mui/icons-material/Block';
import useAddZeroes from '../../../hooks/useAddZeroes';
import { useRouter } from 'next/router';
import VariantModal from './variantModal';
import { useGlobalStore } from '../../../stores/globalStore';
import { useAuthStore } from '../../../stores/authStore';
import { useSnackbar } from 'notistack';
import PriceBox from '../../price-box';
import { Box, Button, Grid } from '@mui/material';
import cssVars from '../../../styles/vars.module.scss'
import { MultiWishListContext } from '../../../Providers/MultiWishListContext';
import { useCartActions } from '../../../hooks/cart/useCartActions';

const VariantItem = ({ variant, parentVariantAttributes, varOptions, metaData, brandsData, loadingIndex }) => {

    const router = useRouter()
    const isAuthenticated = useAuthStore(state => state.token?.length > 0)
    const { t } = useTranslation();

    const { wishLists: data, isFav, addProductToWishlist, removeProductFromWishlist } = useContext(MultiWishListContext)

    const [showWishLists, setShowWishLists] = useState(false);

    const brandName = metaData?.customAttributeMetadata?.items?.at(0)?.attribute_options.filter((x) => x.value === variant.brand?.toString())[0]?.label || null
    const brandImage = brandsData?.categories?.items?.at(0)?.children.filter(brand => brand.name === (metaData.customAttributeMetadata.items?.at(0)?.attribute_options.filter((x) => x.value === variant.brand?.toString())[0]?.label))[0]?.thumbnail || null

    const [modalShow, setModalShow] = useState(false)

    const getVariantAttrValue = (option) => {
        return (parentVariantAttributes.filter((attr) => attr.sku === variant.sku && attr.label === option)[0]?.value || '- - -')
    }

    const getVariantAttrHighlighted = (option) => {
        return (parentVariantAttributes.filter((attr) => attr.sku === variant.sku && attr.label === option)[0]?.highlighted || false)
    }

    const setLoading = useGlobalStore(s => s.setFullPageLoading)

    const { enqueueSnackbar } = useSnackbar();

    const handleFavClick = () => {
        if (isAuthenticated) {
            setShowWishLists(prev => (!prev));
        } else {
            router.push(`/customer/login.html?redirect=${router.asPath}`)
        }
    };


    const { addProductsToCart, disabled: cartDisabled, addBundleProductsToCart } = useCartActions()
    const cartId = useAuthStore(state => state.cartId);
    const cartReady = !cartDisabled
    const openMiniCart = useGlobalStore(s => s.openMiniCart)

    const addToCart = (e, product, closeMe = false) => {
        e.preventDefault();
        setLoading(true)
        if (cartReady) {
            if (product.__typename === "BundleProduct") {
                addBundleProductsToCart({
                    variables: {
                        cartId: cartId,
                        sku: product.sku,
                        quantity: product.custom_stock_item.min_sale_qty,
                        optionId: product.items[0].option_id,
                        id: [product.items[0].options[0].id]
                    },
                    onCompleted: (data) => {
                        setLoading(false);
                        if (data) {
                            if (closeMe) {
                                setModalShow(false)
                            }
                            openMiniCart();
                        }
                    },
                    onError: (err) => {
                        enqueueSnackbar(err.message, { variant: 'error' })
                        setLoading(false);
                    },
                });
            } else {
                addProductsToCart({
                    variables: { cartId: cartId, cartItems: [{ sku: product.sku, quantity: product.custom_stock_item.min_sale_qty }] },
                    onCompleted: (data) => {
                        setLoading(false)
                        if (data) {
                            if (data.action?.user_errors?.length > 0) {
                                for (let i = 0; i < data.action?.user_errors?.length; i++) {
                                    const userError = data.action?.user_errors.at(i)?.message
                                    enqueueSnackbar(userError, { variant: 'error' })
                                }
                            }
                            else {
                                if (closeMe) {
                                    setModalShow(false)
                                }
                                openMiniCart()
                            }
                        }
                    },
                    onError: (err) => {
                        enqueueSnackbar(err.message, { variant: 'error' })
                        setLoading(false)
                    }
                })
            }
        }
        else {
            setLoading(false)
        }
    }

    const isAriba = useGlobalStore(s => s.isAriba)

    return (
        <Grid container columnSpacing={{ lg: 1 }} columns={12} mx={0} px={{ xs: 0, lg: 1 }} display="flex" alignItems="center" width="100%" color={cssVars.lightBlack} fontWeight={600} sx={{ cursor: "pointer", p: "20px 0" }} className={styles.variant} >
            <Grid item xs={12} lg={1} display={{ xs: "flex", lg: "block" }} justifyContent="end" px={{ xs: 1, lg: "none" }} onClick={() => { setModalShow(true) }}>
                <InfoIcon sx={{ ml: 1, mr: 2, fill: cssVars.extraDarkGray, fontSize: { xs: "28px", lg: "24px" } }} />
            </Grid>
            <Grid item xs={12} lg={7} onClick={() => { setModalShow(true) }}>
                <Grid container columnSpacing={{ lg: 1 }} columns={12}>
                    {
                        varOptions.map((option, i) =>
                            <Grid item xs={12} lg={parseInt((12 / varOptions.length).toString())} key={i} >
                                <Box
                                    className={styles.varOption}
                                    px={{ xs: 3, lg: 0 }}
                                    sx={{
                                        py: { xs: 1.5, lg: 0 },
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        display: { xs: "flex", lg: 'block' },
                                        border: i === varOptions.length - 1 ? 0 : 'initial'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: { lg: 'none' },
                                            fontSize: 12,
                                            fontWeight: 500,
                                            py: 1
                                        }}
                                    >
                                        {option}
                                    </Box>
                                    <Box
                                        sx={{
                                            fontSize: 12,
                                            px: 1,
                                            fontWeight: 500,
                                            py: .5,
                                            textAlign: 'center',
                                            width: 'fit-content',
                                            borderRadius: 25,
                                            minWidth: 60,
                                            backgroundColor: getVariantAttrHighlighted(option) ? cssVars.blue : 'initial',
                                            color: getVariantAttrHighlighted(option) ? cssVars.white : 'initial',
                                            border: getVariantAttrHighlighted(option) ? `1px solid ${cssVars.blue}` : `1px solid ${cssVars.lightBlack}`
                                        }}
                                    >
                                        {getVariantAttrValue(option)}
                                    </Box>
                                </Box>
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                lg={4}
                sx={{
                    justifyContent: 'end',
                    alignItems: 'center',
                    display: { xs: "block", lg: "flex" }
                    // display: { xs: 'none', lg: 'flex' }
                }}
            >
                <Box display={{ xs: "none", lg: "block" }}>
                    <PriceBox product={variant} variant="relation" onClick={() => { setModalShow(true) }} />
                </Box>

                <Box sx={{ display: { xs: "block", lg: 'flex' }, position: "relative", width: { xs: "100%", lg: "fit-content" } }}>
                    <Box sx={{ position: 'relative', px: { xs: 2, lg: 0 } }}>
                        <Button
                            className={`${styles['add-btn']}`}
                            sx={{
                                mt: { xs: 3, lg: 0 },
                                backgroundColor: cssVars.blue,
                                '&:hover': {
                                    bgcolor: cssVars.darkBlue
                                },
                                color: cssVars.white,
                                bgcolor: cssVars.blue,
                                width: { xs: "100%", lg: "fit-content" },
                                margin: { xs: "24px 0px 4px 0px  !important", lg: " 0px 0px 0px 24px !important" },
                                height: { xs: "40px !important", lg: "initial" }
                            }}
                            disabled={variant?.availability?.status === "not-available"}
                            onClick={(e) => { addToCart(e, variant) }}
                        >
                            <ShoppingBasketIcon />
                            {t('Add to cart')}
                        </Button>
                    </Box>
                    <Button
                        aria-label="add to wishlist"
                        className={styles['fav-btn']}
                        sx={{
                            visibility: isAriba ? 'hidden' : 'visible',
                            display: { xs: "none", lg: 'inline-flex' },
                            alignItems: 'center',
                            color: isFav(variant.sku) ? cssVars.blue : cssVars.darkGray,
                            border: `1px solid`,
                            borderColor: isFav(variant.sku) ? cssVars.blue : cssVars.darkGray,
                            '&:hover': {
                                color: isFav(variant.sku) ? cssVars.darkBlue : cssVars.extraDarkGray,
                                borderColor: isFav(variant.sku) ? cssVars.darkBlue : cssVars.darkBlue
                            },
                            minWidth: "unset"
                        }}
                        onClick={() => handleFavClick()}
                    >
                        <FavoriteIcon />
                    </Button>
                    <WishListSection
                        sku={variant.sku}
                        show={showWishLists}
                        setShowWishLists={setShowWishLists}
                        sx={{ top: 42, right: 0 }}
                    />
                </Box>
            </Grid>
            <VariantModal varOptions={varOptions} variant={variant} brandName={brandName} brandImage={brandImage} modalShow={modalShow} setModalShow={setModalShow} getVariantAttrValue={getVariantAttrValue} getVariantAttrHighlighted={getVariantAttrHighlighted} addToCart={(e, variant, closeMe) => { addToCart(e, variant, closeMe) }} />
        </Grid>
    );
}

export default VariantItem; 