import { Box } from '@mui/material';
import useTranslation from '../../../hooks/useTranslation'
import styles from './includes-relation.module.scss'
import Item from './item';
import cssVars from '../../../styles/vars.module.scss';

const IncludesRelation = ({ products, title, brands }) => {
    const { t } = useTranslation();

    return (
        products?.length > 0
            ?
            <Box component="section" my={10} className={styles['includes-relation']}>
                <Box component="h2" mb={1.5} fontSize={30} color={cssVars.extraDarkGray}>{t(title)}</Box>
                <Box className={styles.list}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: { xs: 'center', lg: 'flex-start' } }}>
                        {
                            products.map((product) =>
                                <Item key={product.sku} product={product} brands={brands} />
                            )
                        }
                    </Box>
                </Box>
            </Box>
            :
            null
    );
}

export default IncludesRelation;