import { useQuery } from "@apollo/client"
import {
    GET_CUSTOMER_CUSTOMIZATION,
    GET_PRODUCT_ACCESSORIES,
    GET_PRODUCT_FILES,
    GET_PRODUCT_KIT,
    GET_PRODUCT_PARENT_KIT,
    GET_PRODUCT_SPARE_PARTS,
    GET_PRODUCT_SUITABLE_PARTS,
    GET_PRODUCT_USED_WITH,
    GET_PRODUCT_VARIANTS,
    GET_RELATED_PRODUCTS,
    GET_UP_SELL_PRODUCTS,
    PPQ, RANDOM_COMPAIN
} from "../../graphql/products/pdpQueries";
import { useMemo, useState, useEffect } from "react";
import { GET_STORE_CONFIG } from "../../graphql/store/queries";
import { GET_CAT_CHILDREN } from "../../graphql/categories/queries";
import { useCatStore } from "../../stores/catStore";
import { RANDOM_CAMPAIGN } from "../../graphql/bannerPro/queries";

const usePDPDetails = ({ sku }) => {
    const { data: ppq, loading: ppqLoading } = useQuery(PPQ, { variables: { sku }, context: { fetchOptions: { method: "GET" } } })
    const { data: accessories, loading: accessoriesLoading } = useQuery(GET_PRODUCT_ACCESSORIES, { variables: { sku }, context: { fetchOptions: { method: "GET" } } })
    const { data: spareParts, loading: sparePartsLoading } = useQuery(GET_PRODUCT_SPARE_PARTS, { variables: { sku }, context: { fetchOptions: { method: "GET" } } })
    const { data: suitableParts, loading: suitablePartsLoading } = useQuery(GET_PRODUCT_SUITABLE_PARTS, { variables: { sku }, context: { fetchOptions: { method: "GET" } } })
    const { data: usedWith, loading: usedWithLoading } = useQuery(GET_PRODUCT_USED_WITH, { variables: { sku }, context: { fetchOptions: { method: "GET" } } })

    const { data: relatedProducts, loading: relatedProductsLoading } = useQuery(GET_RELATED_PRODUCTS, { variables: { sku }, context: { fetchOptions: { method: "GET" } } })
    const { data: variants, loading: variantsLoading } = useQuery(GET_PRODUCT_VARIANTS, { variables: { sku }, context: { fetchOptions: { method: "GET" } } })
    const { data: kit, loading: kitLoading } = useQuery(GET_PRODUCT_KIT, { variables: { sku }, context: { fetchOptions: { method: "GET" } } })
    const { data: parentKit, loading: parentKitLoading } = useQuery(GET_PRODUCT_PARENT_KIT, { variables: { sku }, context: { fetchOptions: { method: "GET" } } })
    const { data: customerCustomization, loading: customerCustomizationLoading } = useQuery(GET_CUSTOMER_CUSTOMIZATION, { variables: { sku }, context: { fetchOptions: { method: "GET" } } })
    const { data: storeConfigData, loading: storeConfigDataLoading } = useQuery(GET_STORE_CONFIG, { context: { fetchOptions: { method: "GET" } } })
    const { data: selectionsData, loading: selectionsDataLoading } = useQuery(GET_CAT_CHILDREN, { variables: { ids: storeConfigData?.storeConfig.selections_category_id }, skip: !storeConfigData, context: { fetchOptions: { method: "GET" } } })
    const { data: campaign, loading: campaignLoading } = useQuery(RANDOM_CAMPAIGN, { variables: { locations: "pp" }, context: { fetchOptions: { method: "GET" } } })

    const { data: upSellProducts, loading: upSellProductsLoading } = useQuery(GET_UP_SELL_PRODUCTS, { variables: { sku }, context: { fetchOptions: { method: "GET" } } })
    const { data: productFiles, loading: productFilesLoading } = useQuery(GET_PRODUCT_FILES, { variables: { sku }, context: { fetchOptions: { method: "GET" } } })




    const [product, setProduct] = useState(null)
    const [breadcrumbs, setBreadcrumbs] = useState(null)

    useEffect(() => {
        if (ppq) {
            setProduct(ppq.products.items.at(0))
        }
    }, [ppq])

    const catPath = useCatStore(state => state.catPath)
    const path = catPath ? catPath.replace(".html", "").slice(1) : null


    useEffect(() => {
        if (product) {
            let temp = []

            for (const cat of product.categories) {

                if (cat.url_path === path) {
                    temp = [
                        ...cat.breadcrumbs || [],
                        {
                            __typename: 'Breadcrumb',
                            category_name: cat.name,
                            category_url_path: cat.url_path,
                            category_level: cat.breadcrumbs?.length + 2
                        }
                    ]
                }
            }
            if (temp.length === 0) {
                temp = [
                    ...product?.categories?.at(0)?.breadcrumbs || [],
                    {
                        __typename: 'Breadcrumb',
                        category_name: product.categories.at(0)?.name,
                        category_url_path: product.categories.at(0)?.url_path,
                        category_level: product.categories.at(0)?.breadcrumbs?.length + 2
                    }
                ]
            }
            setBreadcrumbs(temp)

        }
    }, [product, path])


    const filteredAccessories = accessories?.products?.items?.at(0)?.accessories?.
        filter(item => (!accessories?.products?.items?.at(0)?.spare_parts_skus?.includes(item.sku) && !accessories?.products?.items?.at(0)?.suitable_skus?.includes(item.sku) && !accessories?.products?.items?.at(0)?.used_with_skus?.includes(item.sku)))

    const filteredSpareParts = spareParts?.products?.items?.at(0)?.spare_parts?.
        filter(item => (spareParts?.products?.items?.at(0)?.spare_parts_skus?.includes(item.sku)))

    const filteredSuitableParts = suitableParts?.products?.items?.at(0)?.suitable_parts?.
        filter(item => (suitableParts?.products?.items?.at(0)?.suitable_skus?.includes(item.sku)))

    const filteredUsedWith = usedWith?.products?.items?.at(0)?.used_with?.
        filter(item => (usedWith?.products?.items?.at(0)?.used_with_skus?.includes(item.sku)))

    const hookData = useMemo(() => ({
        product,
        accessories: filteredAccessories,
        spareParts: filteredSpareParts,
        suitableParts: filteredSuitableParts,
        usedWith: filteredUsedWith,
        productVariants: variants?.products?.items?.at(0)?.variant_products,
        variantOptions: variants?.products?.items?.at(0)?.variant_attributes,
        parentVariantAttributes: variants?.products?.items?.at(0)?.variant_custom_attributes,
        productKit: kit?.products?.items?.at(0)?.product_kit_collection ? [...kit.products.items?.at(0)?.product_kit_collection].map((item, i) => ({ ...item, qtyInKit: JSON.parse(kit.products.items?.at(0).product_kit).at(i).qty })) : null,
        productParentKit: parentKit?.products?.items?.at(0)?.parent_kit_collection,
        similarProducts: relatedProducts?.products?.items?.at(0)?.related_products,
        upSellProducts: upSellProducts?.products?.items?.at(0)?.upsell_products,
        tags: customerCustomization?.products?.items?.at(0)?.customer_customization?.tags,
        attributes: customerCustomization?.products?.items?.at(0)?.customer_customization?.attributes,
        selections: selectionsData?.categories?.items?.at(0)?.children || [],
        breadcrumbs: breadcrumbs,
        campaign: campaign?.randomCampaigns?.at(0) || null,
        productFiles: productFiles?.products?.items?.at(0)?.files || [],
        ppqLoading,
        accessoriesLoading,
        sparePartsLoading,
        suitablePartsLoading,
        usedWithLoading,
        relatedProductsLoading,
        upSellProductsLoading,
        variantsLoading,
        kitLoading,
        parentKitLoading,
        customerCustomizationLoading,
        storeConfigDataLoading,
        selectionsDataLoading,
        campaignLoading,
        productFilesLoading
    }), [
        product,
        accessories, filteredAccessories,
        suitableParts, filteredSuitableParts,
        spareParts, filteredSpareParts,
        usedWith, filteredUsedWith,
        variants,
        kit,
        parentKit,
        relatedProducts,
        upSellProducts,
        customerCustomization,
        selectionsData,
        breadcrumbs,
        campaign,
        productFiles,
        ppqLoading,
        accessoriesLoading,
        suitablePartsLoading,
        sparePartsLoading,
        usedWithLoading,
        relatedProductsLoading,
        upSellProductsLoading,
        variantsLoading,
        kitLoading,
        parentKitLoading,
        customerCustomizationLoading,
        storeConfigDataLoading,
        selectionsDataLoading,
        campaignLoading,
        productFilesLoading
    ])

    return hookData;
}

export default usePDPDetails;