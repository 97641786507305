import Head from "next/head";
import { parseRating } from "../../helpers/parseRating";

const StructuredData = ({ initialData }) => {

    const getImages = () => {
        return initialData.media_gallery.map(image => image.url)
    }

    const getAvailability = () => {
        return initialData.availability.status !== "not-available" ? "https://schema.org/InStock" : "https://schema.org/OutOfStock";
    }

    const getItemProps = () => {
        return JSON.stringify(
            {
                ...{
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    "sku": initialData.sku,
                    "name": (initialData.meta_title || initialData.name),
                    "description": initialData.meta_description,
                    "image": getImages(),
                    ...(
                        initialData?.customer_customization?.brands?.at(0)?.label
                            ?
                            {
                                "brand": {
                                    "@type": "Brand",
                                    "name": initialData.customer_customization.brands.at(0).label
                                },
                            }
                            :
                            {}
                    ),
                    "offers": {
                        "@type": "Offer",
                        "itemCondition": "https://schema.org/NewCondition",
                        "price": initialData.price_range.maximum_price.final_price.value,
                        "priceCurrency": initialData.price_range.maximum_price.final_price.currency,
                        "availability": getAvailability()
                    }
                },
                ...(
                    initialData.review_count > 0
                        ?
                        {
                            "aggregateRating": {
                                "@type": "AggregateRating",
                                "ratingValue": parseRating(initialData.rating_summary),
                                "reviewCount": initialData.review_count
                            }
                        }
                        :
                        {}
                )
            }
        )
    }

    return (
        <Head>
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: getItemProps() }} key={initialData.sku}></script>
        </Head>
    );
}

export default StructuredData;
