import { useState } from "react"
import useTranslation from '../../../hooks/useTranslation'
import styles from "./productDetails.module.scss"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useEffect } from "react";
import { Box, Button, Grid, Skeleton } from "@mui/material";
import Link from "next/link";
import SearchIcon from '@mui/icons-material/Search';
import { useContext } from "react";
import { SearchContext } from "../../../Providers/SearchProvider";
import cssVars from "../../../styles/vars.module.scss";

const Specs = ({ attributes, customerCustomizationLoading, useExtendFunction = true, sx }) => {
    const [showLevel, setShowLevel] = useState(1)

    const essential = attributes?.primary || []
    const secondary = attributes?.secondary || []
    const optional = attributes?.optional || []
    const [specs, setSpecs] = useState(useExtendFunction ? essential : [...essential, ...secondary, ...optional])
    const levelsCount = (essential?.length > 0 ? 1 : 0) + (secondary?.length > 0 ? 1 : 0) + (optional?.length > 0 ? 1 : 0)

    const { t } = useTranslation();

    const handleExpand = () => setShowLevel((level) => level + 1)
    const handleCollapse = () => setShowLevel((level) => level - 1)

    useEffect(() => {
        setSpecs(useExtendFunction ? essential : [...essential, ...secondary, ...optional])
    }, [essential, secondary, optional, useExtendFunction])

    useEffect(() => {
        switch (showLevel) {
            case 1:
                setSpecs(essential)
                break;
            case 2:
                setSpecs([...essential, ...secondary])
                break;
            case 3:
                setSpecs([...essential, ...secondary, ...optional])
                break;
        }

    }, [showLevel])

    const { setSearch } = useContext(SearchContext)


    const handleSearch = (value) => {
        setSearch(value)
        const searchInput = document.querySelector("#mainSearch");
        if (searchInput && document.activeElement !== searchInput) {
            searchInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            document.querySelector("#mainSearch > div > input").focus()
        }
    }

    console.log(specs);
    
    return (
        levelsCount > 0 || customerCustomizationLoading
            ?
            <>
                <Box className={styles['product-specs']} sx={sx}>

                    {
                        specs.some(spec => spec?.attribute_values?.filter(attr => Boolean(attr))?.length > 0)
                            ?
                            specs.map((spec, i) =>
                                spec?.attribute_label && spec?.attribute_values?.filter(attr => Boolean(attr))?.length > 0
                                    ?
                                    <Grid container columnSpacing={{ xs: 2, lg: 3 }} columns={12} key={i} fontSize={12} color={cssVars.lightBlack} className={styles['spec-row']}>
                                        <Grid item xs={5} textAlign="end">
                                            <Box p={.5}>
                                                {spec.attribute_label}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={7} textAlign="start" fontWeight={600} display="flex" alignItems="center" flexWrap="wrap">
                                            {
                                                spec.attribute_values.map(value =>
                                                    <Box
                                                        className="searchable-spec"
                                                        onClick={() => (spec.searchable ? handleSearch(value) : () => { })}
                                                        sx={{ cursor: spec.searchable ? "pointer" : "" }} key={spec.attribute_code}
                                                        style={{
                                                            paddingTop: "2px", paddingBottom: "2px",
                                                            paddingLeft: (spec.has_border ? 4 : 0), paddingRight: (spec.has_border ? 4 : 0),
                                                            marginRight: "6px", border: spec.has_border ? "1px solid #17468F" : "none", borderRadius: "6px"
                                                        }}
                                                    >
                                                        {
                                                            spec.searchable
                                                                ?
                                                                <Box className="searchable-spec"> <Box className="searchable-spec" component="span">{value}</Box> <SearchIcon className="searchable-spec" htmlColor="#0072ba" sx={{ fontSize: 14, height: "100%" }} /></Box>
                                                                :
                                                                value
                                                        }
                                                    </Box>
                                                )}
                                        </Grid>
                                    </Grid>
                                    :
                                    null
                            )
                            :
                            customerCustomizationLoading
                                ?
                                Array.from({ length: 4 }).map((_, i) =>
                                    <Grid container columnSpacing={{ xs: 2, lg: 3 }} columns={12} key={i} fontSize={12} color={cssVars.lightBlack} className={styles['spec-row']}>
                                        <Grid item xs={5} textAlign="end">
                                            <Skeleton width="30%" sx={{ display: "inline-block" }} />
                                        </Grid>
                                        <Grid item xs={7} textAlign="start" fontWeight={600} display="flex" alignItems="center" flexWrap="wrap">
                                            <Skeleton width="40%" sx={{ display: "inline-block" }} />
                                        </Grid>
                                    </Grid>
                                )
                                :
                                null
                    }
                </Box>
                {
                    useExtendFunction &&
                        specs.some(spec => spec?.attribute_values?.filter(attr => Boolean(attr))?.length > 0) &&
                        !customerCustomizationLoading &&
                        levelsCount > 1
                        ?
                        <Button onClick={showLevel === levelsCount ? handleCollapse : handleExpand} className={styles['expand-button']} sx={{ height: "fit-content" }}>
                            <Box>
                                {
                                    showLevel !== levelsCount
                                        ?
                                        <AddIcon sx={{ mr: '10px', fontSize: "18px" }} />
                                        :
                                        <RemoveIcon sx={{ mr: '10px', fontSize: "18px" }}
                                        />
                                }
                            </Box>
                            <Box>{showLevel !== levelsCount ? t("show more") : t("show less")}</Box>
                        </Button>
                        :
                        null
                }
            </>
            :
            null
    )
}

export default Specs;