import { Box } from "@mui/material";
import CampaignCard from "../widgets/promoPro/campaignCard";
import { useMediaQuery } from "@mui/material";

const PDPCampaign = ({ campaign }) => {
    const isLG = useMediaQuery(theme => theme.breakpoints.up('lg'));
    return (
        campaign
            ?
            <Box component="section" my={10}>
                <CampaignCard item={campaign} sx={{ "& .campaign-link": { p: { lg: "1.2rem 1.2rem 120px 1.2rem" }, "& h2": { fontSize: { lg: "1.5rem" } }, "& .cat-description": { fontSize: { lg: "1.25rem" }, width: { lg: "40%" } } } }} />
            </Box>
            :
            null
    );
}

export default PDPCampaign;