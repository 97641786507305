/* eslint-disable @next/next/no-img-element */
import { Box, Grid } from "@mui/material";
import Link from "next/link";
import cssVars from '../../../styles/vars.module.scss'

const SimilarProductCard = ({ product, url, style }) => {
    return (
        <Grid item xs={6} lg={3} px={{ lg: 0 }}>
            <Link href={url} style={{  position: "relative", height: "100%", display: "block", border: product.itIsMe ? `3px solid ${cssVars.blue}` : `none` }} className={style['similar-product']}>
                <img src={product.small_image?.url} alt="" />
                <Box component="p" className={style.name}>{product.name}</Box>
                <Box sx={{ position: "absolute", top: 5 }} >
                    {
                        product.customer_customization?.tags?.map(tag =>
                            <Box key={tag} sx={{ border: `1px solid ${cssVars.extraDarkGray}`, textAlign: "center", zIndex: 2, background: "#fff", marginBottom: "4px", padding: "4px", fontSize: "12px", borderRadius: "6px" }} >
                                {tag}
                            </Box>
                        )
                    }
                </Box>
            </Link>
        </Grid>
    );
}

export default SimilarProductCard;