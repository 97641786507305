import { gql } from "@apollo/client";

export const PREVIEW_BANNER_ITEMS = gql`
query previewBannerItems($previewDate: String) {
  data: bannerItems(previewDate: $previewDate) {
    background_image
    color
    content
    description
    icon
    id
    image
    isEnabled
    link
    title
    toDate
  }
}
`

export const PREVIEW_CAMPAIGN_ITEMS = gql`
query previewCampaignItems($previewDate: String) {
  data: campaigns(filters: { location: "homepage" }, previewDate: $previewDate) {
    title
    description
    icon
    color
    id
    image
    link
    location
  }
}
`;

export const RANDOM_CAMPAIGN = gql`
query randomCampaigns($locations: [String!]!) {
  randomCampaigns(locations: $locations, pageSize: 1) {
    title
    description
    icon
    color
    id
    image
    link
    location
    __typename
  }
}
`;