import { Box } from "@mui/material";
import Selections from "../selections";

const PDPSelections = ({ selections }) => {
    return (
        selections && selections.length > 0 ?
            <Box component="section" my={10}>
                <Selections selections={selections} forPDP={true} />
            </Box>
            : null
    );
}

export default PDPSelections;