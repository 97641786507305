import ReactImageGallery from "react-image-gallery";
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Box } from "@mui/material";
import ProductDownloads from "../../product-category-downloads";
import ProductCategoryDownloads from "../../product-category-downloads";


const ShowBox = ({ media_gallery, files }) => {
    console.log(media_gallery);

    return (
        <Box sx={{ "& button": { height: "fit-content !important" }, mt: 2, px: { xs: 1.5, lg: 0 }, position: { lg: "sticky" }, top: 0 }}>
            <ReactImageGallery items={
                media_gallery?.map(i => { return { original: i.url, thumbnail: i.url, originalAlt: (i.url + " image link"), thumbnailAlt: (i.url + " thumbnail link") } })
            }

                showNav={true}
                showPlayButton={false}
                showFullscreenButton={false}
                showBullets={false}
                swipingTransitionDuration={true}
                renderLeftNav={(onClick, disabled) => (
                    <Box component="span" sx={{ display: "block", cursor: "pointer" }} className="image-gallery-left-nav" onClick={onClick} disabled={disabled}>
                        <ChevronLeft />
                    </Box>
                )}
                renderRightNav={(onClick, disabled) => (
                    <Box component="span" sx={{ display: "block", cursor: "pointer" }} className="image-gallery-right-nav" onClick={onClick} disabled={disabled}>
                        <ChevronRight />
                    </Box>
                )}
            />
            <Box sx={{ mt: 2 }} mt={1}>
                <ProductCategoryDownloads files={files} />
            </Box>
        </Box>
    );
}

export default ShowBox;