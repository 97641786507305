import { Skeleton } from "@mui/material";
import Breadcrumb from "../breadcrumb";

const PdpBreadcrumb = ({ breadcrumbs, product, ppqLoading }) => {
    return (
        breadcrumbs && product
            ?
            <Breadcrumb breadcrumbs={breadcrumbs} activeName={product.name} />
            :
            ppqLoading
                ?
                <Skeleton variant="rectangular" height={20} sx={{ mb: 2, mt: { xs: 2.5, lg: .5 }, width: { xs: "90%", lg: "40%" } }} />
                :
                null

    );
}

export default PdpBreadcrumb;