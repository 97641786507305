import { useEffect } from 'react';
import styles from './product-variants.module.scss'
import VariantItem from "./variantItem";
import { useState } from 'react';
import { Box, Grid } from '@mui/material';
import cssVars from '../../../styles/vars.module.scss'

const Variants = ({ variants, parentVariantAttributes: vars, metaData, brandsData, loadingIndex = 0 }) => {
    const [attrs, setAttrs] = useState([])
    useEffect(() => {
        if (vars) {
            try {
                const sortedVars = [...vars].sort((a, b) => (a.position - b.position)).map(attr => attr.label)
                const temp = Array.from(new Set(sortedVars))
                setAttrs(temp)
            } catch (error) {
                setAttrs([])
            }
        }
    }, [vars])

    return (
        vars?.length > 0 && attrs?.length > 0
            ?
            <Box my={10} className={styles['product-variants']} >
                <Grid container columnSpacing={1} columns={12} sx={{ width: "100%", mx: 0 }} className={styles.header}>
                    <Grid item xs={12} lg={1} ></Grid>

                    <Grid item xs={12} lg={7}>
                        <Grid container columnSpacing={1} columns={12} color={cssVars.lightBlack} fontWeight={600} p="20px 0">
                            {
                                attrs.map((name, i) =>
                                    <Grid item xs={12} lg={parseInt((12 / attrs.length).toString())} key={i} fontWeight={600}>
                                        {name}
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>

                </Grid>
                <Box className={styles.items}>
                    {
                        variants.map(variant =>
                            <VariantItem key={variant.uid} variant={variant} parentVariantAttributes={vars} varOptions={attrs} metaData={metaData} brandsData={brandsData} loadingIndex={loadingIndex} />
                        )
                    }
                </Box>
            </Box >
            :
            null
    );
}

export default Variants;