import { Grid } from "@mui/material";
import ThemeCard from "../theme-card";
import Carousel from "../carousel";

const Selections = ({ selections, forPDP = false }) => {
    const responsive = {
        0: {
            items: 1,
            nav: false,
            margin: 12
        },
        600: {
            items: 2,
            nav: false,
            margin: 17
        },
        992: {
            items: 3,
            nav: false,
            margin: 35
        },
        1200: {
            items: 4,
            nav: true,
            margin: 35
        },
    }

    return (
        forPDP
            ?
            <Carousel
                className="owl-theme"
                dots={true}
                responsive={responsive}
            >
                {
                    selections?.map(cat =>
                        <ThemeCard key={cat.title} cat={cat} />
                    )
                }
            </Carousel>
            :
            <Grid container columns={(forPDP ? 4 : 2)} columnSpacing={3} sx={{ mt: 0 }}>
                {selections?.map((item) =>
                    <Grid item xs={2} md={1} key={item.uid} sx={{ my: 1.5 }}>
                        <ThemeCard cat={item} wideView />
                    </Grid>
                )
                }
            </Grid>
    );
}

export default Selections;